import BannerImage from "../../../../images/CaseStudiesImagesLogo/PreScouter.png";

const data = [
  {
    slugurl: "/Prescouter",
    data: [
      {
        Banner: [
          {
            sector: "Information Technology",
            headingtext: "Streamline Web Scraping with Auxeelator Extension",
            headingpara:
              "Auxeelator Extension offers intuitive web scraping features, helping businesses, researchers, and marketers collect accurate and organized data efficiently from a variety of web sources.",
            image: BannerImage,
          },
        ],
        heading: "Streamline Web Scraping with Auxeelator Extension",
        casestudy: {
          Problem:
            "Efficiently scraping data from multiple web sources while ensuring accuracy, scalability, and usability for non-technical users.",
          challenge: [
            {
              heading: "The Challenges:",
              challengeli: [
                { First: "Scalability: Extracting data efficiently from multiple web pages without affecting browser performance." },
                { First: "Data Accuracy: Ensuring all available data is captured without duplication." },
                { First: "Usability: Creating a user-friendly interface for non-technical users." },
                { First: "State Management: Ensuring smooth operation of all features without conflicts." },
              ],
            },
            {
              heading: "The Solutions:",
              challengeli: [
                { First: "HTML DOM Values: Retrieve the URL and scrape data using the HTML DOM of the webpage." },
                { First: "Current Page Tab: Instantly scrape data from the active page and display it on the notebook." },
                { First: "Multiple URL Tab: Scrape data from multiple tabs simultaneously with just one click." },
                { First: "Google Search Scraping: Users can search and scrape multiple URLs based on search results." },
              ],
            },
            {
              heading: "Impact:",
              challengeli: [
                { First: "Streamlined data collection process, improving efficiency for users." },
                { First: "Accuracy and scalability ensure reliable data extraction across multiple sources." },
                { First: "User-friendly design, enabling non-technical users to gather data effortlessly." },
                { First: "Increased productivity and reduced manual data collection time for businesses, researchers, and marketers." },
              ],
            },
          ],
        },
        companyinfo: {
          logourl: "prescouter",
          about: "About",
          aboutpara:
            "The Auxeelator Extension simplifies and accelerates web scraping for businesses, researchers, and marketers, offering powerful features like Google Search Scraping, Current Page Tab, and Multiple URL Tab for efficient data extraction.",
          companysector: [
            { first: "INDUSTRY:", secound: "IT" },
            { first: "LOCATION:", secound: "Global" },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
