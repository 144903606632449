import React from "react";
import data from "./PhotoshopExtensionData";
import BannerImage from "../../../../images/CaseStudiesImagesLogo/PhotoshopExtension.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const PhotoshopExtension = () => {
    return (
        <>
            <SuperSEO 
                title="Slava Blazer Photography - Enhancing Client Engagement Through AI-Powered Email Responses"
                description="Slava Blazer Photography optimizes client communication with an AI-driven email reply system, improving engagement and response times."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { BannerImage },
                        summaryCardImageAlt: "Slava Blazer Photography",
                        summaryCardSiteUsername: "SlavaBlazerPhoto",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default PhotoshopExtension;
