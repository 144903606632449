import BannerImage from "../../../../images/CaseStudiesImagesLogo/Nexuschatbot.png";

const data = [
  {
    slugurl: "/Nexuschatbot",
    data: [
      {
        Banner: [
          {
            sector: "Technology",
            headingtext: "Enhanced ERP User Assistance with Nexus Chatbot",
            headingpara:
              "Nexus Chatbot optimizes ERP platforms by automating responses, handling diverse queries, and offering multi-language support, ensuring a smooth user experience.",
            image: BannerImage,
          },
        ],
        heading: "Enhanced ERP User Assistance with Nexus Chatbot",
        casestudy: {
          Problem: "Challenges in managing diverse user queries and ensuring up-to-date responses with an efficient chatbot.",
          challenge: [
            {
              heading: "The Challenges:",
              challengeli: [
                { First: "Diverse Queries: Addressing a wide range of user questions." },
                { First: "Knowledge Relevance: Regular updates needed to reflect platform changes." },
                { First: "Seamless Integration: Ensuring smooth integration with existing ERP platforms." },
                { First: "Scalability and Customization: Handling vast data and customization needs." },
                { First: "Language Adaptability: Supporting multiple languages for a global audience." },
              ],
            },
            {
              heading: "The Solutions:",
              challengeli: [
                { First: "AI-Driven Responses: Uses OpenAI API for context-aware responses." },
                { First: "Two-Layered Design: Parent and Child Nodes to manage query complexity." },
                { First: "Separate Admin UI: Allows easy knowledge base updates in various file formats." },
                { First: "Multi-Language Support: Enhances accessibility across regions." },
                { First: "Edge Case Handling: Decision algorithms for unexpected scenarios." },
              ],
            },
            {
              heading: "Impact:",
              challengeli: [
                { First: "Improved user experience with real-time, accurate responses." },
                { First: "Reduced support workload, allowing focus on strategic tasks." },
                { First: "Adaptability and relevance through regular updates." },
                { First: "Global accessibility with multi-language support." },
              ],
            },
          ],
        },
        companyinfo: {
          logourl: "Nexuschatbot",
          about: "About",
          aboutpara:
            "The Nexus Chatbot is an AI-driven assistant designed to streamline ERP platform usage by enhancing user engagement, ensuring accuracy, and improving operational efficiency.",
          companysector: [
            // { first: "INDUSTRY:", secound: "Technology" },
            // { first: "LOCATION:", secound: "Global" },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
