import BannerImage from "../../../../images/CaseStudiesImagesLogo/PhotoshopExtension.png";

const data = [
  {
    slugurl: "/PhotoshopExtension",
    data: [
      {
        Banner: [
          {
            sector: "Photography/Technology",
            headingtext: "Slava Blazer Photography",
            headingpara:
              "Slava Blazer Photography specializes in high-quality photography and editing services. With their AI-powered email response system, they enhance client engagement and streamline communication.",
            image: BannerImage,
          },
        ],
        heading:
          "Enhancing Client Engagement Through AI-Powered Email Responses",
        casestudy: {
          Problem:
            "Managing a high volume of client inquiries with consistent quality, while ensuring quick responses, presented challenges that impacted client satisfaction.",
          challenge: [
            {
              heading: "The Challenges:",
              challengeli: [
                { First: "Handling a high volume of client inquiries." },
                { First: "Maintaining consistent quality in replies." },
                { First: "Reducing response time to meet client expectations." },
                { First: "Integrating AI without disrupting the workflow." },
              ],
            },
            {
              heading: "The Solution:",
              challengeli: [
                { First: "AI Email Response System: Implemented a Flask-based system using OpenAI’s API to generate quick, personalized email replies." },
                { First: "Custom Instruction Set: Configured AI to analyze client context and preferences, ensuring thoughtful responses." },
                { First: "Structured Response Format: Replies follow a consistent format with polite greetings and professional sign-offs." },
                { First: "Adjustable Tone: Allows for flexibility in tone, enhancing the client experience." },
              ],
            },
            {
              heading: "Impact:",
              challengeli: [
                { First: "Slava Blazer Photography enhanced client engagement by reducing response times, improving satisfaction, and streamlining operations, allowing more focus on core services." },
              ],
            },
          ],
        },
        companyinfo: {
          logourl: "slavablazer",
          about: "About",
          aboutpara:
            "Slava Blazer Photography provides premier photography services, focusing on creativity and client relationships. With AI integration, they continue to prioritize client satisfaction and high-quality service.",
          companysector: [
            {
              first: "Company Name:",
              secound: "Slava Blazer Photography",
            },
            {
              first: "INDUSTRY:",
              secound: "Photography Technology",
            },
            {
              first: "LOCATION:",
              secound: "United States",
            },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
