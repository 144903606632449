import React from "react";
import data from "./prescouterData";
import BannerImage from "../../../../images/CaseStudiesImagesLogo/PreScouter.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const prescouter = () => {
    return (
        <>
            <SuperSEO
                title="Streamline Web Scraping with Auxeelator Extension"
                description="The Auxeelator Extension simplifies web scraping, offering powerful features like Google Search Scraping, Current Page Tab, and Multiple URL Tab for efficient data collection."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { BannerImage },
                        summaryCardImageAlt: "Auxeelator Extension for Web Scraping",
                        summaryCardSiteUsername: "prescouter",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default prescouter;
