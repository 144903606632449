import React from "react";
import data from "./NexuschatbotData";
import BannerImage from "../../../../images/CaseStudiesImagesLogo/Nexuschatbot.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const Nexuschatbot = () => {
    return (
        <>
            <SuperSEO
                title="Enhanced ERP User Assistance with Nexus Chatbot"
                description="The Nexus Chatbot delivers real-time assistance, automates query handling, and supports multi-language processing, enhancing user experience for ERP platforms."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { BannerImage },
                        summaryCardImageAlt: "Nexus Chatbot for ERP Systems",
                        summaryCardSiteUsername: "YourCompany",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default Nexuschatbot;
