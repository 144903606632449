import BannerImage from "../../../../images/CaseStudiesImagesLogo/AIchatbox.png";

const data = [
  {
    slugurl: "/AIchatbox",
    data: [
      {
        Banner: [
          {
            sector: "Technology",
            headingtext: "Revolutionizing Document-Based Queries with AI Chatbox",
            headingpara:
              "Our AI Chatbox streamlines information retrieval from uploaded PDFs, delivering personalized, instant, and secure responses to enhance productivity.",
            image: BannerImage,
          },
        ],
        heading: "Revolutionizing Document-Based Queries with AI Chatbox",
        casestudy: {
          Problem:
            "Professionals face challenges in retrieving accurate information from large documents while ensuring security and personalized responses.",
          challenge: [
            {
              heading: "The Challenges:",
              challengeli: [
                { First: "Document Processing: Managing diverse document formats efficiently." },
                { First: "Personalization: Ensuring user-specific and context-relevant responses." },
                { First: "Data Security: Safeguarding user-uploaded document content." },
                { First: "Integration: Seamless integration with existing systems." },
              ],
            },
            {
              heading: "The Solutions:",
              challengeli: [
                { First: "Advanced NLP: Uses OpenAI API for precise document-based answers." },
                { First: "User-Specific Knowledge Management: Tailored knowledge bases for personalized responses." },
                { First: "Secure Document Handling: Ensures data integrity and privacy." },
                { First: "Seamless Integration: Easily integrates with existing workflows." },
              ],
            },
            {
              heading: "Impact:",
              challengeli: [
                { First: "Significantly enhanced efficiency in document-based information retrieval." },
                { First: "Reduced time and effort for users across industries." },
                { First: "Improved user satisfaction with instant, accurate responses." },
                { First: "Secure and reliable platform for data-driven decision-making." },
              ],
            },
          ],
        },
        companyinfo: {
          logourl: "aichatbox",
          about: "About",
          aboutpara:
            "The AI Chatbox leverages advanced NLP and AI technologies to provide instant, accurate responses for document-based queries, tailored to user needs while ensuring data security.",
          companysector: [
            { first: "INDUSTRY:", secound: "Technology" },
            { first: "LOCATION:", secound: "Udaipur" },
          ],
        },
        customer: [],
      },
    ],
  },
];

export default data;
