import React from "react";
import data from "./EmailExtractorData";
import BannerImage from "../../../../images/CaseStudiesImagesLogo/EmailExtractor.png";
import { SuperSEO } from "react-super-seo";
import Casebanner from "../CaseBanner/Casebanner";

const EmailExtractor = () => {
    return (
        <>
            <SuperSEO
                title="Efficiently Extracting Emails from Web Sources with Advanced Functionality"
                description="Our Email Extractor Extension simplifies gathering emails from web sources, enhancing efficiency and productivity for businesses, researchers, and marketers."
                lang="en"
                twitter={{
                    twitterSummaryCard: {
                        summaryCardImage: { BannerImage },
                        summaryCardImageAlt: "Email Extractor Extension",
                        summaryCardSiteUsername: "YourCompany",
                    },
                }}
            />

            {data.map((items, key) => (
                <Casebanner key={key} data={items.data} />
            ))}
        </>
    );
};

export default EmailExtractor;
