import React from "react";
import ServiceIndustries from "../../../Comman/ServiceComponents/SaasProductService/ServiceIndustries";

import Vector3 from "../../../../images/chrome-extension-develpment/vector3.svg";
import Vector7 from "../../../../images/chrome-extension-develpment/vector7.svg";
import E_commerce from "../../../../images/chrome-extension-develpment/e-commerce.png";
import saas from "../../../../images/chrome-extension-develpment/saas.png";
import mining from "../../../../images/chrome-extension-develpment/mining.png";
import recruitment from "../../../../images/chrome-extension-develpment/recruitment.png";
import CaseStudyBanner from "../../../Comman/CaseStudyBanner/CaseStudyBanner";
import CaseStudyTopCard from "../../../Comman/CaseStudyBanner/CaseStudyTopCard";


import AlpineAI from "../../../../images/CaseStudiesImagesLogo/AlpineAI.png";
import DaisyZendesk from "../../../../images/CaseStudiesImagesLogo/DaisyZendesk.png";
import logo_lakebrains from "../../../../images/logo/logo_lakebrains.webp";
import Nexuschatbot from "../../../../images/CaseStudiesImagesLogo/Nexuschatbot.png";
import AIchatbox from "../../../../images/CaseStudiesImagesLogo/AIchatbox.png";



const cashStuduCards = [

  {
    img: DaisyZendesk,
    title: "Daisy Zendesk Extension",
    content: "Enhance Zendesk Workflow with GPT-4 and Daisy AI-Powered Thread Summarizer Chrome Extension.",
    URL: "/casestudy/DaisyZendeskExtension/",
    category: "AI/ML",
  },
  {
    img: AlpineAI,
    title: "Alpine.AI Extension",
    content: "Alpine.ai: Seamlessly Protect Your Online Privacy.",
    URL: "/casestudy/AlpineAIExtension/",
    category: "AI/ML",
  },
  {
    img: logo_lakebrains,
    title: "Empathy",
    content: "Extract insights from text effortlessly with our AI/ML product.",
    URL: "/casestudy/empathy/",
    category: "AI/ML",
  },
  {
    img: logo_lakebrains,
    title: "ChatGPT Q & A",
    content: "Effortlessly generate summaries and answer questions from PDFs using AI.",
    URL: "/casestudy/chatGPT-qa/",
    category: "AI/ML",
  },
  {
    img: Nexuschatbot,
    title: "Nexus Chatbot",
    content: "AI-driven chatbot for ERP systems with real-time, multi-language support.",
    URL: "/casestudy/Nexuschatbot/",
    category: "AI/ML",
  },
  {
    img: AIchatbox,
    title: "AI Chatbox",
    content: "Effortlessly get accurate responses from uploaded PDFs with our secure AI Chatbox.",
    URL: "/casestudy/AIchatbox/", 
    category: "AI/ML",
}

];

const industryData = [
  {
    img: Vector3,
    title: "Health Care ",
  },
  {
    img: Vector7,
    title: "Finance",
  },
  {
    img: E_commerce,
    title: "E-commerce",
  },
  {
    img: saas,
    title: "SaaS",
  },
  {
    img: mining,
    title: "Mining",
  },
  {
    img: recruitment,
    title: "Recruitmemt",
  },
];


export default function AIMLcaseStudyPage() {
  return (
    <>
      <CaseStudyBanner subHeading="Case Studies" heading="AI/ML Development" description="Get to know more about the AI/ML Development" />

      <CaseStudyTopCard cashStuduCards={cashStuduCards} subHeading="A Transformative Case Study" heading="AI/ML Case Studies" description="Revolutionize operations with AI/ML. Efficiency and innovation intersect." />

      <ServiceIndustries industryData={industryData} subHeading="Industries Transformed By Cloud" heading="Industries" description="Our experts will work with you to understand and solve specific industry problems using SaaS." />

    </>
  );
}
